<script setup>
import { ref, computed, nextTick, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useInitData, useScreenSize } from "@shared/lib";
import { REQUST_STATUSES, STORAGE_KEY } from "@shared/const";
import { SvgTemplate } from "@shared/ui";
import { StoriesViewer } from "./StoriesViewer";
import { StoriesPreview } from "./StoriesPreview";

const storiesViewer = ref(null);
const store = useStore();

const route = useRoute();

const { isDesktop } = useScreenSize();

const currentStoryBlockIdx = ref(null);

const isViewerActive = ref(false);

const openViewer = (storyBlockInx, storyIdx) => {
	if (!isPending.value) {
		currentStoryBlockIdx.value = storyBlockInx;
		isViewerActive.value = true;
		nextTick(() => storiesViewer.value.open(storyIdx));
	}
};

const onClose = () => {
	isViewerActive.value = false;
};

const initData = useInitData();

initData(async () => {
	await store.dispatch("stories/getStories");
});
const storiesStatus = computed(() => {
	return store.getters["stories/storiesStatus"];
});

const isPending = computed(() => {
	return storiesStatus.value === REQUST_STATUSES.PENDING;
});

const unwatch = watch(storiesStatus, () => {
	if (storiesStatus.value === REQUST_STATUSES.SUCCESS) {
		unwatch();
		const storyId = route.query.strory_id;
		if (storyId) {
			stories.value.some((storyBlock, storyBlockIdx) => {
				const storyIdx = storyBlock.story_item.findIndex(
					(story) => +story.item_id === +storyId && !story.storie_url.endsWith(".mp4")
				);
				if (storyIdx !== -1) {
					openViewer(storyBlockIdx, storyIdx);
					return true;
				}
				return false;
			});
		}
	}
});

const stories = computed(() => {
	const res = store.getters["stories/stories"];
	if (isPending.value) {
		return Array(res.length || 5).fill(null);
	}

	return res.sort((lhs, rhs) => rhs.place_id - lhs.place_id);
});

const currentStoriesByBlock = computed(() => {
	let res = [];
	if (currentStoryBlockIdx.value !== null) {
		const items = stories.value[currentStoryBlockIdx.value].story_item;
		res = items;
	}
	return res;
});

const previewsImagesRefs = ref([]);

const addPreviewRef = (index, ref) => {
	if (ref?.imageRef) {
		previewsImagesRefs.value[index] = ref.imageRef;
	}
};

const viewedStories = ref(
	new Set(JSON.parse(localStorage.getItem(STORAGE_KEY.VIEW_STORIES) || "[]"))
);

const onNextBlock = () => {
	viewedStories.value.add(stories.value[currentStoryBlockIdx.value].place_id);
	localStorage.setItem(STORAGE_KEY.VIEW_STORIES, JSON.stringify(Array.from(viewedStories.value)));
	if (currentStoryBlockIdx.value < stories.value.length - 1) {
		currentStoryBlockIdx.value += 1;
	} else {
		storiesViewer.value.close();
	}
};

const onPrevBlock = () => {
	if (currentStoryBlockIdx.value > 0) {
		currentStoryBlockIdx.value -= 1;
	}
};

const isViewed = (id) => {
	return viewedStories.value.has(id);
};

const storiesContainer = ref(null);
const canScrollLeft = ref(false);
const canScrollRight = ref(true);
const shouldHideButtons = ref(false);

const calculateScrollState = () => {
	const container = storiesContainer.value;
	if (!container) return;

	const totalScrollWidth = container.scrollWidth;
	const visibleWidth = container.clientWidth;

	shouldHideButtons.value = totalScrollWidth <= visibleWidth;

	const scrollLeft = Math.round(container.scrollLeft);
	const maxScrollLeft = Math.round(totalScrollWidth - visibleWidth);

	canScrollLeft.value = scrollLeft > 0;
	canScrollRight.value = scrollLeft < maxScrollLeft;
};

const scroll = (direction) => {
	const itemWidth = previewsImagesRefs.value[0].offsetWidth + 20;
	storiesContainer.value.scrollLeft += direction * itemWidth;
};

const scrollLeft = () => scroll(-1);
const scrollRight = () => scroll(1);

onMounted(() => {
	const container = storiesContainer.value;
	if (container) {
		container.addEventListener("scroll", calculateScrollState);
		window.addEventListener("resize", calculateScrollState);
	}
});

onUnmounted(() => {
	const container = storiesContainer.value;
	if (container) {
		container.removeEventListener("scroll", calculateScrollState);
		window.removeEventListener("resize", calculateScrollState);
	}
});

watch(stories.value, (newVal, oldVal) => {
	if (newVal !== oldVal) calculateScrollState();
});
</script>

<template>
	<div class="stories-wrapper">
		<div class="stories" ref="storiesContainer">
			<button
				v-if="!shouldHideButtons && isDesktop && !isPending"
				class="slider-btn slider-btn-prev"
				:class="{ disabled: !canScrollLeft }"
				type="button"
				@click="scrollLeft()"
			>
				<SvgTemplate name="chevron" />
			</button>
			<StoriesPreview
				v-for="(story, index) in stories"
				:key="'slider_stories_key-' + index"
				:story="story"
				:is-pending="isPending"
				:ref="(ref) => addPreviewRef(index, ref)"
				:is-viewed="isViewed(story?.place_id)"
				@click="openViewer(index)"
			/>
			<button
				v-if="!shouldHideButtons && isDesktop && !isPending"
				class="slider-btn slider-btn-next"
				:class="{ disabled: !canScrollRight }"
				type="button"
				@click="scrollRight()"
			>
				<SvgTemplate name="chevron" />
			</button>
		</div>

		<StoriesViewer
			v-if="isViewerActive"
			ref="storiesViewer"
			:stories="currentStoriesByBlock"
			:target-elements="previewsImagesRefs"
			:current-story-block-idx="currentStoryBlockIdx"
			@nextBlock="onNextBlock"
			@prevBlock="onPrevBlock"
			@close="onClose"
		/>
	</div>
</template>

<styles lang="scss" scoped>
@import "./Stories.scss";
</styles>
